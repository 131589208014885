export const RequirementGatheringCommonFunctions = {
    validateData(data, templates){
        const defaultValues = {"numeric":0, "text":"", "checkbox":false};
        templates.forEach((template) => {
          if(data[template.id]){
            const arr = data[template.id]
            arr.forEach((obj) => {
              template.fields.forEach((field) => {
                if(field.id && obj[field.id]==null){
                  obj[field.id] = {
                    value: defaultValues[field.type]
                  }
                }else if(field.id && field.type === "checkbox"){
                  if (typeof(obj[field.id].value)!='boolean'){
                    obj[field.id].value = false
                  }
                }
              })
            })
          }else{
            const obj = {}
            template.fields.forEach((field) => {
              if(field.id){
                obj[field.id] = defaultValues[field.type]
              }
            })
            data[template.id] = [obj]
          }
        })
      },
      cleanData(templates, data){
        templates.forEach((template) => {
          const fields = new Set();
          template.fields.forEach((field) => {
            if(field.id){
              fields.add(field.id)
            }
          });
          data[template.id].forEach((obj) => {
            Object.keys(obj).forEach((field) => {
              if(!fields.has(field)){
                delete obj[field]
              }
            })
          })
        });
      }
}