<template>
    <div>
      <div class ="container-fluid">
        <div class="row">
          <Sidebar />
          <div class="col-md-10 offset-md-2 pr-10 mt-5">
            <div v-if="templatesMissing">
              <h1>Please upload the requirement gathering config file.</h1>
            </div>
            <div v-else-if="loading" class="text-center">
              <div class="d-flex flex-column justify-content-center align-items-center p-4">
                <v-progress-circular indeterminate></v-progress-circular>
                <span class="small brand-text-primary py-2">Loading ...</span>
              </div>
            </div>
            <div v-else class="d-flex">
              <v-tabs dense>
                <v-tab v-for="template in templates">
                  {{ template.label}}
                </v-tab>
  
                <v-tab-item v-for="template in templates">
                  <div style="height:100%">
                    <RequirementGatheringSheet :template="template" :data="data[template.id]" :key="refreshSheet" :readOnly="pendingChanges ||  !isAuthorized"></RequirementGatheringSheet>
                    <div class="d-flex">
                      <v-file-input id="selectedFile" v-model="file" style="display: none;"></v-file-input>
                      <v-btn class="btn" onclick="document.getElementById('selectedFile').click();" :disabled="pendingChanges ||  !isAuthorized" depressed>Import</v-btn>
                      <v-btn class="btn" depressed @click="exportExcel">Export</v-btn>
                      <v-btn class="btn" depressed @click="auditLogs">Audit Logs</v-btn>
                      <v-btn class="btn" :disabled="pendingChanges ||  !isAuthorized" depressed @click="save">Submit</v-btn>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs>
            </div>
          </div>
        </div>
        <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarColor">
          <div>{{ snackbarText }}</div>
  
          <template v-slot:action="{attrs}">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
          </template>
        </v-snackbar>
      </div>
    </div>
  </template>
  
  <script>
  import Sidebar from '../components/Sidebar.vue'
  import RequirementGatheringSheet from '../components/RequirementGatheringSheet.vue';
  import {RequirementGatheringCommonFunctions} from '../common/requirement-gathering'
    export default {
      name: 'RequirementGatheringWorkBook',
      components: {
        Sidebar,
        RequirementGatheringSheet
      },
      watch: {
        file(newValue, oldValue){
          if(this.file){
            this.importRequirementGatheringData()
          }
        }
      },
      computed: {
        tenantId(){
          const tenantId = sessionStorage.tenant_id;
          return tenantId.split("/").slice(-1)[0];
        },
        isAuthorized(){
          return localStorage.roles.includes('implementation')
        }
      },
      data(){
        return {
          templates: [],
          data:{},
          loading: true,
          pendingChanges: false,
          templatesMissing: false,
          file: null,
  
          snackbar:false,
          snackbarColor: "",
          snackbarText: "",
          refreshSheet: 0,
        }
      },
      methods: {
        initialize(){
          const url = `/api/Tenants/fetchRequirements/${this.tenantId}`
          this.$http.get(url).then((res) => {
            this.templates = res.data.templates;
            this.data = res.data.data;
            this.loading = false;

            if(!this.templates){
              this.templatesMissing = true;
            }
  
            this.$http.get("/api/RequirementGatheringMods").then((res) => {
              if(res.data[0]){
                this.pendingChanges = true;
  
                this.snackbarText = "Pending approval, please Approve/Decline from Req. Gathering Approval tab to proceed";
                this.snackbarColor = "red";
                this.snackbar = true;
              }
            })
            RequirementGatheringCommonFunctions.validateData(this.data, this.templates)
          })
        },
        exportExcel(){
          this.$http
            .post("/api/Tenants/exportRequirementGatheringData/", {"details": this.data, "templates": this.templates})
            .then((res) => {
              let dwnElement = document.createElement("a");
              const date = new Date();
              dwnElement.setAttribute("download", `${sessionStorage.tenant_name}-${date.toDateString()}-${date.toLocaleTimeString()}`);
              dwnElement.setAttribute("href", res.data.documentData);
              dwnElement.click();
            });
        },
        importRequirementGatheringData(){
          var reader = new FileReader();
          var self = this;
          reader.onload = (function (file){
            return function(e){
              var binaryData = e.target.result;
              var base64String = window.btoa(binaryData)
              self.$http
                .post("/api/Tenants/importRequirementGatheringData", {documentData:base64String, templates:self.templates})
                .then((res) => {
                  self.templates.forEach((template) => {
                    if(res.data[template.id]){
                      self.data[template.id] = res.data[template.id]
                     }
                  })
                  self.refreshSheet+=1;
  
                  self.snackbarColor = "green";
                  self.snackbarText = "Data Imported Successfully"
                  self.snackbar=true;
                  self.file = null;
                })
            }
          })(this.file);
          reader.readAsBinaryString(this.file)
        },
        save(){
          const payload = {
            details: this.data
          }
          RequirementGatheringCommonFunctions.cleanData(this.templates, this.data)
          this.$http.post("/api/RequirementGatheringMods/", payload).then((res) => {
            this.snackbarColor = "green";
            this.snackbarText = "Data Saved Successfully";
            this.snackbar=true;
            setTimeout(() => {
                this.initialize();
            }, 2500);
          })
        },
        auditLogs(){
          this.$router.push({
            name: 'RequirementGatheringHistory',
            params: {},
          });
        },
      },
      created(){
        this.initialize()
      }
    }
  </script>
  
  <style scoped lang="css">
      .d-flex{
        display:flex
      }
      .justify-content-center{
        justify-content: center;
      }
      .align-items-center{
        align-items: center;
      }
  
      .btn{
        margin:10px 5px;
        background-color: #3a9bdc !important;
        
      }
  
      .theme--light.v-btn {
        color: white;
        
      }
  </style>