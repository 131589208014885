<template>
  <v-card class="elevation-1 m-3">
    <div class="flex-column">
      <div class="col-md-10 offset-md-2 pr-10 mt-5 p-4">
        <div class="row">
          <div class="col-4">
            <strong>Upload Image</strong>
          </div>
        </div>
        <div class="overflow-x-scroll">
          <div class="container-fluid">
            <div class="row">
              <Sidebar />
            </div>
          </div>
        </div>
        <br />
        <div class="d-flex">
          <div>
            <div v-if="this.tenant.name" class="my-4">
              <span class="pb-1 h6">Entity Name:</span>
              <span>
                {{ this.tenant.name }}
              </span>
            </div>
          </div>
        </div>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-if="mode === 'Insert'"
            v-model="docName"
            :rules="[(v) => !!v || 'Enter an image identifier']"
            label="Image Identifier"
            outlined
            dense
            solo
            clearable
            class="required"
            required
          ></v-text-field>
          <div v-else class="my-4">
            <span class="pb-1 h6">Document Name:</span>
            <span>
              {{ this.docName }}
            </span>
          </div>
          <v-file-input
            v-model="image"
            :rules="[
              (v) => !!v || 'Upload an image file',
              (v) =>
                !v || v.size <= 200000 || 'File size must be less than 200KB',
            ]"
            accept=".png, .svg, .svg+xml"
            class="required"
            label="Upload Image"
            required
          ></v-file-input>
          <v-btn depressed color="primary" @click.prevent="submitForm">
            Submit Image
          </v-btn>
        </v-form>
      </div>
    </div>
    <div>
      <v-snackbar
        class="m-5"
        v-model="snackbar"
        :timeout="timeout"
        :color="snackcolor"
      >
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-card>
</template>

<script>
import Sidebar from '../components/Sidebar.vue';
import axios from 'axios';

export default {
  name: 'UploadImage',
  components: {
    Sidebar,
  },
  props: {
    mode: { type: String, default: 'Insert' },
    docName: { type: String },
  },
  data() {
    return {
      tenant: {},
      docName: '',
      image: null,
      timeout: 5000,
      snackbar: false,
      snackcolor: 'red',
      message: '',
      valid: true,
      updateIfExisting: true,
    };
  },

  methods: {
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    },
    async submitForm() {
      console.log(this.mode);
      if (!this.$refs.form.validate()) return;
      const base64ImageString = await this.convertFileToBase64(this.image);
      if (this.mode === 'Insert') {
        this.$http
          .post('/api/ImageStores/insertImage', {
            docName: this.docName,
            docbase64: base64ImageString,
          })
          .then((response) => {
            this.snackbar = true;
            this.snackcolor = 'green';
            this.message = 'Image Uploaded Successfully';
            setTimeout((e) => {
              this.$router.push({
                name: 'ViewImageStore',
              });
            }, 2000);
          })
          .catch((error) => {
            console.error(error.response.data.error.message);
            this.snackbar = true;
            this.snackcolor = 'red';
            this.message = error.response.data.error.message;
          });
      } else {
        this.$http
          .post('/api/ImageStores/upsertImage', {
            docName: this.docName,
            docbase64: base64ImageString,
          })
          .then((response) => {
            this.snackbar = true;
            this.snackcolor = 'green';
            this.message = 'Image Uploaded Successfully';
            setTimeout((e) => {
              this.$router.push({
                name: 'ViewImageStore',
              });
            }, 2000);
          })
          .catch((error) => {
            console.error(error.response.data.error.message);
            this.snackbar = true;
            this.snackcolor = 'red';
            this.message = error.response.data.error.message;
          });
      }
    },
  },

  async created() {
    if (sessionStorage.length == 0) {
      this.$router.push({
        name: 'Login',
      });
    }
    this.tenant = {
      id: sessionStorage.tenant_id,
      name: sessionStorage.tenant_name,
    };
  },
};
</script>

<style scoped>
#ruleName,
#ruleDesc {
  border: 0;
  border-bottom: 1px solid black;
  border-radius: 0;
}

.v-textarea {
  font-size: 0.75rem;
  line-height: 0.8rem;
}

.theme--light.v-btn {
  color: white;
}
</style>
