<template>
  <header>
    <!-- <nav class="navbar navbar-dark bg-header fw-bolder justify-content-center w-100">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="../assets/images/logo.png" alt="" width="30" height="30" class="d-inline-block align-text-top" />
        </a>
        <p class="navbar-brand w-90">Rules Engine</p>
      </div>
    </nav> -->

    <div class="w-100">
      <nav class="
          navbar
          fixed-top
          navbar-expand-sm
          bg-header
          fw-bolder
        ">
        <div class="container-fluid">
          <a class="navbar-brand pb-2 m-0 " style="margin-left: 1rem" href="#">
            <img src="../assets/images/lenders_cooperative.svg" alt="" height="30" class="d-inline-block align-text-top" />
            &nbsp;
            &nbsp;

            <!--The Awe<font color="#ff6b00">Summ-IT</font> Rules
            Manager-->
          </a>
          <!-- <img src="../assets/images/STG.png" alt="" height="20" class="d-inline-block align-text-top " /> -->
          &nbsp;Automation Platform
          <div v-if="homepage" id="navbarCollapse" class="collapse navbar-collapse">
            <ul class="nav navbar-nav ms-auto">
              <li class="nav-item">
                <!-- <a href="#" class="tenant">Entity&nbsp;:&nbsp;{{ tenantName }}</a> -->
                <!-- <i class="mdi-arrow-up-down" @click.prevent="openSwitchTenantDialog()"></i> -->
                <div class="d-flex align-bottom">
                  <span>Entity&nbsp;:&nbsp;{{ tenantName }}</span>
                  <v-icon title="Switch Tenant" @click.prevent="openSwitchTenantDialog()" class="ms-3 fw-bold brand-text-primary">mdi-arrow-down-drop-circle-outline</v-icon>
                </div>
              </li>
            </ul>
            <ul class="nav navbar-nav ml-10p">
              <li class="nav-item dropdown">
                <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                  <v-icon>person</v-icon>&nbsp;{{
                    userName.toUpperCase()
                  }}&nbsp;&nbsp;&nbsp;
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                  <!-- <a href="#" class="dropdown-item">Switch Tenant</a> -->
                  <a href="#" class="dropdown-item">Settings</a>
                  <div class="dropdown-divider"></div>
                  <!-- <router-link to="/logout" class="dropdown-item">Logout</router-link> -->
                  <a href="#" class="dropdown-item" @click="logout()">Logout</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <v-dialog v-model="switchTenantDialog" persistent max-width="25%">
      <v-card class="p-2 d-flex flex-column">
        <v-card-title class="d-flex justify-center">
          <span class="sub-header brand-text-primary pb-5">Switch Tenant</span>
        </v-card-title>
        <v-card-text class="py-0">
          <div class="px-2 col-12 py-0">
            <label><span class="fs-9 pb-1 mandatory certification-question">Tenant Name</span></label>
            <v-select class="pt-4" v-model="newTenant" :items="tenants" item-text="tenantName" item-value="tenantId" outlined dense solo clearable></v-select>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex align-self-end gap-2">
          <button class="btn btn-white" @click.prevent="closeSwitchTenantDialog">Cancel</button>
          <button class="btn btn-primary brand-text-white" :disabled="asyncDisable" @click.prevent="switchTenant">Switch Tenant</button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {
    title: String,
  },
  data() {
    return {
      userName: "",
      tenantName: "",
      homepage: true,
      switchTenantDialog: false,
      asyncDisable: false,
      newTenant: null,
      tenants: []
    };
  },
  methods: {
    async openSwitchTenantDialog() {
      await this.populateTenants();
      this.switchTenantDialog = true;

    },
    closeSwitchTenantDialog() {
      this.switchTenantDialog = false;
    },
    async switchTenant() {
      this.asyncDisable = true;
      if (this.newTenant) {
        const newTenantObj = this.tenants.find((t) => t.tenantId === this.newTenant);
        if (newTenantObj) {
          await this.$http.post('/api/AppUsers/switch-tenant', { tenantId: newTenantObj.tenantId, tenantName: newTenantObj.tenantName }).then((res) => {
            this.asyncDisable = false;
            if (newTenantObj) {
              sessionStorage.setItem("tenant_id", newTenantObj.tenantId);
              sessionStorage.setItem("tenant_name", newTenantObj.tenantName);
              this.$router.go();
            }
            this.switchTenantDialog = false;
          }).catch((err) => {
            this.asyncDisable = false;
            this.$emit("show-alert", false, err.response.data.error.message);
            this.switchTenantDialog = false;
          });
        } else {
          this.asyncDisable = false;
          this.switchTenantDialog = false;
        }
      }
    },
    async populateTenants() {
      this.tenants = [];
      this.newTenant = sessionStorage.tenant_id.replace(/\//g, '');
      if (this.userName != "") {
        let userTenantUrl = `/api/UserTenantMappings?filter[where][username]=${this.userName}&filter[include][tenRel]`;
        const res = await this.$http.get(userTenantUrl);
        if (res.status === 200 && res.data.length) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].tenRel) {
              this.tenants.push({
                tenantName: res.data[i].tenRel.tenantName,
                tenantId: res.data[i].tenRel.tenantId,
              });
            }
          }
          this.tenants.sort((a, b) => a.tenantName > b.tenantName ? 1 : -1);
        }
      }
    },
    async logout() {
      //let accessToken = localStorage.getItem("access_token");
      let url = "/api/AppUsers/logout";
      // if (accessToken) {
      //   url += "?access_token=" + accessToken;
      // }
      try {
        const res = await this.$http.post(url, {});
        if (res.status === 204) {
          sessionStorage.removeItem("auth_token");
          sessionStorage.removeItem("username");
          sessionStorage.removeItem("tenant_id");
          sessionStorage.removeItem("tenant_name");
          localStorage.removeItem("access_token");
          localStorage.removeItem("swagger_accessToken");
          localStorage.removeItem("roles");
          this.$router.push({ name: "Login" });
        }
      } catch (e) {
        console.error(e.response.data.error.message);
      }
    },
  },
  watch: {
    $route(to, from) {
      // console.log('localStorage',localStorage);
      // console.log('sessionStorage',sessionStorage);
      // console.log('to',to);
      // console.log('from',from);
      this.userName = sessionStorage.username;
      this.tenantName = sessionStorage.tenant_name;
      if (to.name === "Home" || to.name === "Login" || to.path === '/') this.homepage = false;
      if (sessionStorage.isLoggedIn === 'true') this.homepage = true;
    },
  },

  created() {
    this.homepage = false;
    if (sessionStorage.username) {
      this.userName = sessionStorage.username;
      this.tenantName = sessionStorage.tenant_name;
      if (sessionStorage.isLoggedIn === 'true') this.homepage = true;
    }
  },
};
</script>

<style scoped>
.brand-text-primary {
  color: var(--primary) !important;
}
</style>