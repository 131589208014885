<template>
    <div>
      <div class ="container-fluid">
        <div class="row">
          <Sidebar /> 
          <div class="col-md-10 offset-md-2 pr-10 mt-5">
                    <div v-if="loading" class="text-center">
                        <div class="d-flex flex-column justify-content-center align-items-center p-4">
                        <v-progress-circular indeterminate></v-progress-circular>
                        <span class="small brand-text-primary py-2">Loading ...</span>
                        </div>
                    </div>
                    <div v-else-if="areChangesPresent" class="d-flex">
                        <v-tabs dense>
                                <v-tab v-for="template in templates">
                                    {{  template.label }}
                                </v-tab>

                                <v-tab-item v-for="template in templates">
                                    <div style="height: 100%">
                                        <RequirementGatheringSheet :template="template" :data="data[template.id]" :readOnly="true"></RequirementGatheringSheet>
                                        <div class="d-flex">
                                            <v-btn class="btn btn-success" depressed :disabled="!isAuthorized" @click="approve">Approve</v-btn>
                                            <v-btn class="btn btn-danger" depressed :disabled="!isAuthorized" @click="decline">Decline</v-btn>
                                        </div>
                                    </div>
                                </v-tab-item>
                            </v-tabs>
                    </div>
                    <div v-else>
                        <h1>No Changes Present</h1>
                    </div>
                </div>
            </div>
        </div>
        <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarColor">
        <div>{{ snackbarText }}</div>

        <template v-slot:action="{attrs}">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
        </template>
      </v-snackbar>
    </div>
</template>
<script>
    import Sidebar from '../components/Sidebar.vue'
    import RequirementGatheringSheet from '../components/RequirementGatheringSheet.vue';
    import {RequirementGatheringCommonFunctions} from '../common/requirement-gathering'

    export default{
        name: 'RequirementGatheringModWorkBook',
        components:{
            Sidebar,
            RequirementGatheringSheet
        },
        computed: {
            tenantId(){
                const tenant_id = sessionStorage.tenant_id;
                return tenant_id.split("/").slice(-1)[0];
            },
            isAuthorized(){
                return localStorage.roles.includes('implementation') && sessionStorage.username != this.createdBy;
            }
        },
        data(){
            return {
                templates: [],
                data: {},
                updatedData: {},

                modelId: null,

                snackbar: false,
                snackbarColor: "",
                snackbarText: "",

                loading: true,
                areChangesPresent: false,
                createdBy: "",
            }
        },
        methods: {
            compareData(originalData, updatedData){
                RequirementGatheringCommonFunctions.cleanData(this.templates, originalData);
                RequirementGatheringCommonFunctions.cleanData(this.templates, updatedData);
                this.templates.forEach((template) => {
                    const originalObjs = originalData[template.id];
                    const updatedObjs = updatedData[template.id];

                    const len = originalObjs.length > updatedObjs.length ? originalObjs.length : updatedObjs.length;
                    for(let i =0 ; i<len ; i++){
                        let originalObj = originalObjs[i];
                        let updatedObj = updatedObjs[i];

                        if(originalObj && updatedObj){
                        template.fields.forEach((field) => {
                                if(field.id){
                                    if(originalObj[field.id].value != updatedObj[field.id].value){
                                        originalObj[field.id].value = updatedObj[field.id].value;
                                originalObj[field.id].updated = true;
                            }
                            }
                        })
                        }else{
                            const className = originalObj ? "delete" : "add";
                            if(updatedObj){
                                originalObjs.push(updatedObj);
                                originalObj = originalObjs[i];
                    }

                template.fields.forEach((field) => {
                    if(field.id){
                                    originalObj[field.id].updated = true;
                                    originalObj[field.id].className = className
                    }
                            })
                        }
                    }
                })
            },
            approve(){
                const autoscope = this.tenantId === "default" ? "/default" : `/default/${this.tenantId}`;
                this.$http.post("/api/RequirementGatheringMods/approve", {id: this.modelId, autoscope:autoscope, tenantId: this.tenantId}).then((res) => {
                    this.snackbarColor = true;
                    this.snackbarText = "Changes Approved";
                    this.snackbar = true;
                    this.areChangesPresent = false;
                })
            },
            decline(){
                const autoscope = this.tenantId === "default" ? "/default" : `/default/${this.tenantId}`;   
                this.$http.post("/api/RequirementGatheringMods/reject", {id: this.modelId, autoscope: autoscope}).then((res) => {
                    this.snackbarColor = "green";
                    this.snackbarText = "Changes Rejected";
                    this.snackbar = true;
                    this.areChangesPresent = false;
                });
            }

        },
        created(){
            const url = `/api/Tenants/fetchRequirements/${this.tenantId}`;
            this.$http.get(url).then((res) => {
                this.templates = res.data.templates;
                this.data = res.data.data;
                this.$http.get("/api/RequirementGatheringMods").then((res) => {

                    if(res.data[0]){
                        this.modelId = res.data[0].id;
                        this.createdBy = res.data[0]._createdBy;
                        this.updatedData = res.data[0].details;
                        RequirementGatheringCommonFunctions.validateData(this.data, this.templates)
                        RequirementGatheringCommonFunctions.validateData(this.updatedData, this.templates)
                        this.compareData(this.data, this.updatedData)
                        this.areChangesPresent = true;
                    }

                    if(localStorage.roles.includes('implementation') && sessionStorage.username === this.createdBy){
                        this.snackbarText = "Same user cannot approve or decline changes.";
                        this.snackbarColor = "red";
                        this.snackbar = true;
                    }
                    this.loading = false;
                })
            })
        }
    }
</script>

<style scoped lang="css">
    .d-flex{
      display:flex
    }
    .justify-content-center{
      justify-content: center;
    }
    .align-items-center{
      align-items: center;
    }

    .btn{
      margin:10px 5px;
    }

    .theme--light.v-btn {
      color: white;
    }
</style>