<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="pl-5">
                    <v-btn class="btn btn-primary" depressed @click="back">Back</v-btn>
                </div>
                <div class="pl-5 mt-5 pb-10" v-if="type=='all'">
                    <v-card>
                        <v-card-title>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details class="col-4 p-0"></v-text-field>
                        </v-card-title>
                        <v-data-table :headers="headers" :items="logs" :search="search" :items-per-page="5">
                            <template v-slot:item="row">
                                <tr>
                                    <td>{{ row.item.srlno }}</td>
                                    <td>{{ row.item._modifiedBy }}</td>
                                    <td>{{ row.item.createdOnFormatted }}</td>
                                    <td>{{ row.item.modifiedOnFormatted }}</td>
                                    <td>
                                    <div class="col d-flex justify-content-end">
                                        <v-icon class="fas p-2" @click="() => viewAuditLog(row.item)" title="View">mdi-eye</v-icon>
                                    </div>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card>
                </div>
                <div class="pl-5 mt-5 pb-10" v-else>
                    <v-tabs dense>
                        <v-tab v-for="template in templates">
                            {{ template.label}}
                        </v-tab>

                        <v-tab-item v-for="template in templates">
                            <div style="height:100%">
                            <RequirementGatheringSheet :template="template" :data="data[template.id]" :readOnly="true"></RequirementGatheringSheet>
                            <div class="d-flex">
                                <v-btn class="btn btn-primary" depressed @click="exportExcel">Export</v-btn>
                            </div>
                            </div>
                        </v-tab-item>
                    </v-tabs>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { CommonFunctions } from "../common/common-functions.js";
import RequirementGatheringSheet from "../components/RequirementGatheringSheet.vue";
import {RequirementGatheringCommonFunctions} from '../common/requirement-gathering'

    export default({
        name:'RequirementGatheringHistory',
        components: {
            RequirementGatheringSheet,
        },
        data(){
            return {
                type:"all",
                search: "",
                headers: [
                    {
                    value: "srlno",
                    text: "#",
                    class: "text-white primary tableHeaderClass"
                    },
                    {
                    value: "_modifiedBy",
                    text: "Last Mod. By",
                    class: "text-white primary tableHeaderClass"
                    },
                    {
                    value: "createdOnFormatted",
                    text: "Created On",
                    class: "text-white primary tableHeaderClass",
                    },
                    ,
                    {
                    value: "modifiedOnFormatted",
                    text: "Modified On",
                    class: "text-white primary tableHeaderClass",
                    },
                    {
                    text: "",
                    class: "text-white primary tableHeaderClass"
                    },
                ],
                logs: [],
                defaultData: {},
                templates: {},
                data: {},
            }
        },
        computed: {
            tenantId(){
                const tenantId = sessionStorage.tenant_id;
                return tenantId.split("/").slice(-1)[0];
            }
        },
        methods: {
            back(){
                if(this.type=="all"){
                    this.$router.push({
                        name: 'RequirementGatheringWorkBook',
                        params: {},
                    });
                }else{
                    this.type="all"
                }
            },
            viewAuditLog(obj){
                this.type = "one";

                this.data = obj.srlno == this.logs.length? this.defaultData : this.logs[obj.srlno].requirementGathering;

                RequirementGatheringCommonFunctions.validateData(this.data, this.templates)
                RequirementGatheringCommonFunctions.validateData(obj.requirementGathering, this.templates)
                this.compareData(this.data, obj.requirementGathering);
            },
            exportExcel(){
                this.$http
                .post("/api/Tenants/exportRequirementGatheringData/", {"details": this.data, "templates": this.templates})
                .then((res) => {
                    let dwnElement = document.createElement("a");
                    const date = new Date();
                    dwnElement.setAttribute("download", `${sessionStorage.tenant_name}-${date.toDateString()}-${date.toLocaleTimeString()}`);
                    dwnElement.setAttribute("href", res.data.documentData);
                    dwnElement.click();
                });
            },
            compareData(originalData, updatedData){
                RequirementGatheringCommonFunctions.cleanData(this.templates, originalData);
                RequirementGatheringCommonFunctions.cleanData(this.templates, updatedData);
                this.templates.forEach((template) => {
                    const originalObjs = originalData[template.id];
                    const updatedObjs = updatedData[template.id];

                    const len = originalObjs.length > updatedObjs.length ? originalObjs.length : updatedObjs.length;
                    for(let i =0 ; i<len ; i++){
                        let originalObj = originalObjs[i];
                        let updatedObj = updatedObjs[i];

                        if(originalObj && updatedObj){
                        template.fields.forEach((field) => {
                                if(field.id){
                                    if(originalObj[field.id].value != updatedObj[field.id].value){
                                        originalObj[field.id].value = updatedObj[field.id].value;
                                originalObj[field.id].updated = true;
                            }
                            }
                        })
                        }else{
                            const className = originalObj ? "delete" : "add";
                            if(updatedObj){
                                originalObjs.push(updatedObj);
                                originalObj = originalObjs[i];
                    }

                template.fields.forEach((field) => {
                    if(field.id){
                                    originalObj[field.id].updated = true;
                                    originalObj[field.id].className = className
                    }
                            })
                        }
                    }
                })
            }
        },
        created(){
            const url = `/api/Tenants/${this.tenantId}/fetchHistory`;
            this.$http.get(url).then((res) => {
                this.logs = res.data;
                let count = 1;
                this.logs = this.logs.map((m) => {
                    m.srlno = count;
                    count++;
                    m.createdOnFormatted = CommonFunctions.convertTime(m.requirementGatheringCreatedOn,false);
                    m.modifiedOnFormatted = CommonFunctions.convertTime(m._modifiedOn,false);
                    return m;
                });
            })

            this.$http.get('api/SpreadingConfigs/fetch/requirement_gathering').then((res) => {
                this.defaultData = res.data.configData.default_data;
                this.templates = res.data.configData.templates;
            })
        }
        
    })
</script>
<style scoped lang="css">   
    .theme--light.v-btn {
      color: white;
    }

    .btn{
      margin:10px 5px;
    }
</style>