<template>
  <div class="container mb-10">
    <div class="row">
      <div class="col-lg-4 col-md-8"></div>
      <div class="col-lg-4 col-md-8">
        <div class="container login-card ">
          <!-- <div class="main-card"> -->
          <div class="col-12 mx-auto d-flex justify-content-center">
            <div class="row g-3 pt-1 pb-lg-0 pb-3">
              <div>
                <div class="d-block card-on-background">
                  <div class="row">
                    <div class="col-12 px-3">
                      <img class="img-fluid" src="../assets/images/lenders_cooperative.svg" alt="Lenders Cooperative" />
                      <p class="h5 pb-0 mb-0 mt-2">Welcome to the</p>
                      <h4><b>Rule Engine Portal</b></h4>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="card-on-background">
                  <div class="card-text p-1">
                    <div class="form-group">
                      <div class="px-0">
                        <div class="d-flex justify-content-between">
                          <span class="text-secondary text-dark h6" for="username">
                            Username<span class="text-danger">*</span>
                          </span>
                          <!-- <a class="text-right pretty-link" style="text-underline-offset: 4px;" href="#"><small class="fw-normal">Forgot Username?</small></a> -->
                        </div>
                        <div class="input-group mb-3">
                          <span class="input-group-text bg-color">
                            <i class="mdi mdi-account-circle-outline white-icon"></i>
                          </span>
                          <input class="form-control" type="text" name="userId" id="userId" placeholder="- - - - - - - - - - -" required="required" v-model="userId" autocomplete="off" v-on:blur="populateTenants" />
                        </div>
                      </div>
                      <!-- <br /> -->
                      <div class="px-0">
                        <div class="d-flex justify-content-between">
                          <span class="text-secondary text-dark h6" for="username">
                            Password<span class="text-danger">*</span>
                          </span>
                          <!-- <a class="text-right pretty-link" style="text-underline-offset: 4px;" href="#"><small class="fw-normal">Forgot Password?</small></a> -->
                        </div>
                        <div class="input-group mb-3">
                          <span class="input-group-text bg-color">
                            <i class="mdi mdi-lock-outline white-icon"></i>
                          </span>
                          <input class="form-control" type="password" name="password" id="password" placeholder="- - - - - - - - - - -" required="required" v-model="password" />
                        </div>
                      </div>
                      <!-- <br /> -->
                      <!-- <label class="form-label fw-bold" v-show="tenants.length > 1" for="tenantId">Tenant</label> -->
                      <div class="d-flex justify-content-between">
                        <span class="text-secondary text-dark h6" v-show="tenants.length > 1" for="tenantId">
                          Tenant<span class="text-danger">*</span>
                        </span>
                      </div>
                      <!-- <input class="form-control" type="text" name="tenantId" id="tenantId" v-model="tenantId" /> -->
                      <div class="input-group mb-3" v-show="tenants.length > 1">
                        <span class="input-group-text bg-color">
                          <i class="mdi mdi-office-building-outline white-icon"></i>
                        </span>
                        <select class="form-control" name="tenantId" id="tenantId" v-model="tenantId">
                          <option v-for="tenant in tenants" :value="tenant.tenantId" :key="tenant.tenantName">
                            {{ tenant.tenantName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="level stretch mt-4 respLoginButtons">
                      <button type="submit" class="btn btn-secondary text-white float-end" @click="doLogin()">
                        Sign In
                      </button>
                    </div>
                    <!-- <br /> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
      <div class="col-lg-4 col-md-8"></div>
    </div>
  </div>
</template>

<!--
<template>
<div class="container login-card pt-5">
    <div class="main-card">
        <div class="col-12 col-xl-11 mx-auto">
            <div class="row g-3 pt-1 pb-lg-0 pb-3">
                <div class="col-lg-8">
                    <div class="d-block card-on-background">
                        <div class="row">
                            <div class="col-12 px-3 pb-3">
                                <p class="h2 pb-0 mb-0">Welcome to the</p>
                                <h1><b>Rules Engine Portal</b></h1>
                                <hr class="dash" />
                            </div>

                            <div class="col-12 col-xl-6"></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card-on-background">
                        <div style="font-size: 1.25rem; font-weight: bold">
                            Registered User Login
                        </div>
                        <div class="card-text p-2">
                            <div class="form-group">
                                <label class="form-label fw-bold" for="userId">Login Id</label>
                                <input class="form-control" type="text" name="userId" id="userId" v-model="userId" v-on:blur="populateTenants" />
                                <br />
                                <label class="form-label fw-bold" for="password">Password</label>
                                <input class="form-control" type="password" name="password" id="password" v-model="password" />
                                <br />
                                <label class="form-label fw-bold" v-show="tenants.length > 1" for="tenantId">Tenant</label>
                                 <input class="form-control" type="text" name="tenantId" id="tenantId" v-model="tenantId" />
                                <select class="form-control" v-show="tenants.length > 1" name="tenantId" id="tenantId" v-model="tenantId">
                                    <option v-for="tenant in tenants" :value="tenant.tenantId" :key="tenant.tenantName">
                                        {{ tenant.tenantName }}
                                    </option>
                                </select>
                                <br />
                            </div>
                            <div class="level stretch mt-4 respLoginButtons">
                                <button type="submit" class="btn btn-primary" @click="doLogin()">
                                    Sign In
                                </button>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>-->

<script>
export default {
  name: "Login",
  data() {
    return {
      userId: "",
      password: "",
      tenantId: "",
      tenants: [],
    };
  },
  methods: {
    async doLogin() {
      let loginurl = "/api/AppUsers/login";
      let username = this.userId;
      let password = this.password;
      let tenantId = this.tenantId;
      let userObj = {
        username,
        password,
        tenantId,
      };
      try {
        const response = await this.$http.post(loginurl, userObj);
        //if (response.status === 200) window.location.href = './uploadrule';;
        if (response.status === 200) {
          let loginInfo = response.data;
          let url = "/api/AppUsers/aboutMe";
          let accessToken;
          if (loginInfo && loginInfo.id) {
            accessToken = loginInfo.id;
            sessionStorage.setItem("auth_token", accessToken);
            sessionStorage.setItem("username", loginInfo.username);
            if (loginInfo.ctx)
              sessionStorage.setItem("tenant_id", loginInfo.ctx.tenantId);
            localStorage.setItem("access_token", accessToken);
            localStorage.setItem("swagger_accessToken", accessToken);
            localStorage.setItem("roles", loginInfo.roles);
          } else {
            accessToken = localStorage.getItem("access_token");
          }
          if (accessToken) {
            url += "?access_token=" + accessToken;
          }
          const res = await this.$http.get(url);
          let aboutMe = res.data;
          if (res.status === 200) {
            sessionStorage.setItem(
              "tenant_name",
              aboutMe.tenantName ? aboutMe.tenantName : ""
            );
            sessionStorage.setItem("isLoggedIn", true);
            this.$router.push({
              name: "RulesDashboard",
              params: {
                type: "all"
              },
            });
          }
        } else {
          this.$emit("show-alert", false, e.response.data.error.message);
        }
      } catch (e) {
        this.$emit("show-alert", false, e.response.data.error.message);
      }
    },
    async populateTenants() {
      this.tenants = [];
      if (this.userId != "") {
        let userTenantUrl = `api/UserTenantMappings?filter[where][username]=${this.userId}&filter[include][tenRel]`;
        const res = await this.$http.get(userTenantUrl);
        let userTenants = res.data;
        if (res.status === 200 && res.data.length) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].tenRel) {
              this.tenants.push({
                tenantName: res.data[i].tenRel.tenantName,
                tenantId: res.data[i].tenRel.tenantId,
              });
            }
          }
          if(this.tenants.length){
            this.tenantId = this.tenants[0].tenantId;
          }
          this.tenants.sort((a, b) => a.tenantName > b.tenantName ? 1 : -1);
        }
      }
    },
  },
  created() {
    sessionStorage.setItem("isLoggedIn", false);
  },
  mounted() {
    sessionStorage.setItem("isLoggedIn", false);
  }
};
</script>̀

<style scoped>
.login-card {
  background-color: rgba(250, 250, 250, 1);
  margin-top: 3rem;
}

.main-card {
  background-color: rgb(240, 240, 240);
}

.bg-color {
  background-color: #002e6d !important ;
}

.white-icon {
  color: white !important;
}
</style>
