<template>
  <div>
    <div class="container-fluid overflow-auto">
      <div class="row">
        <Sidebar />
        <div class="col-md-10 offset-md-2 pr-10 mt-5">
          <div class="pl-5 mt-5 pb-10">
            <div class="mt-4 mb-4">
              <strong>Image Store</strong>
            </div>
            <v-card>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  class="col-4 p-0"
                ></v-text-field>
                <v-btn
                  depressed
                  class="btn btn-primary ms-auto"
                  @click="uploadImage"
                  v-if="dashboardType != 'Pending Organization Config Approval'"
                >
                  Upload New Image
                </v-btn>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="logs"
                :search="search"
                :items-per-page="10"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.srlno }}</td>
                    <td>{{ row.item.docName }}</td>
                    <td>{{ row.item.level }}</td>
                    <td>
                      {{ new Date(row.item._modifiedOn).toLocaleString() }}
                    </td>
                    <td>
                      <div class="col d-flex justify-content-end">
                        <v-icon
                          class="fas p-2"
                          @click="
                            downloadImage(row.item.docbase64, row.item.docName)
                          "
                          title="Download Response"
                          >mdi-download-circle</v-icon
                        >
                        <v-icon
                          class="fas p-2"
                          @click="editImage(row.item.docName)"
                          title="Edit"
                          >mdi-pencil</v-icon
                        >
                        <v-icon
                          v-if="showDelete(row.item.level)"
                          class="fas p-2"
                          @click="deleteImage(row.item.id)"
                          title="Delete"
                          >mdi-delete
                        </v-icon>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <div>
      <v-snackbar
        class="m-5"
        v-model="snackbar"
        :timeout="timeout"
        :color="snackcolor"
      >
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import Sidebar from '../components/Sidebar.vue';
import { CommonFunctions } from '../common/common-functions.js';
import axios from 'axios';

export default {
  name: 'ViewImageStore',
  components: {
    Sidebar,
  },
  data() {
    return {
      tenant: {},
      search: '',
      headers: [
        {
          value: 'srlno',
          text: '#',
          class: 'text-white primary tableHeaderClass',
        },
        {
          value: 'docName',
          text: 'Image Identifier',
          class: 'text-white primary tableHeaderClass',
        },
        {
          value: 'level',
          text: 'Level',
          class: 'text-white primary tableHeaderClass',
        },
        {
          value: '_modifiedOn',
          text: 'Modified On',
          class: 'text-white primary tableHeaderClass',
        },
        {
          text: '',
          class: 'text-white primary tableHeaderClass',
        },
      ],
      logs: [],
      snackbar: false,
      snackcolor: 'red',
      message: '',
    };
  },
  methods: {
    showDelete(level) {
      return this.tenant.id === 'default' || level === 'Bank';
    },
    uploadImage() {
      this.$router.push({
        name: 'UploadImage',
        params: {
          mode: 'Insert',
        },
      });
    },
    downloadBase64Image(base64Data, docName) {
      // Extract the image type from the Data URL
      const match = base64Data.match(/^data:image\/([\w+-]+);base64,/);
      if (!match) {
        console.error('Invalid Data URL format');
        return;
      }
      const imageType = match[1].toLowerCase();
      const supportedImageTypes = ['png', 'svg', 'svg+xml'];
      if (!supportedImageTypes.includes(imageType)) {
        console.error('Unsupported image type: ' + imageType);
        return;
      }
      let extension = imageType; // By default, use the image type as the file extension
      // Adjust the file extension for SVG with "data:image/svg+xml"
      if (imageType === 'svg+xml') {
        extension = 'svg';
      }

      const base64Part = base64Data.split(',')[1];
      const byteCharacters = atob(base64Part);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: `image/${extension}` });
      // Create a temporary download link
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${docName}.${extension}`;
      // Simulate a click on the download link
      document.body.appendChild(link);
      link.click();
      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    },

    async fetchLogs() {
      let url = `/api/ImageStores?filter={ "order": "_modifiedOn DESC", "limit": 100 }`;
      await axios
        .get(url)
        .then((res) => {
          this.logs = [];
          let datares = res.data;
          if (datares.length) {
            let count = 1;
            datares.map((m, idx) => {
              m.srlno = idx + 1;
              return m;
            });
            this.logs = datares;
          }
        })
        .catch((error) => {
          console.error(
            'There has been a problem with your fetch operation:',
            error
          );
        });
    },
    viewLogDetails(log) {
      if (log) {
        this.$router.push({
          name: 'ViewLog',
          params: {
            id: log.id,
          },
        });
      }
    },
    downloadImage(log, docName) {
      this.downloadBase64Image(log, docName);
    },
    editImage(docName) {
      this.$router.push({
        name: 'UploadImage',
        params: {
          mode: 'Update',
          docName,
        },
      });
    },
    deleteImage(docId) {
      this.$http
        .delete(`/api/ImageStores/${docId}`)
        .then((response) => {
          this.snackbar = true;
          this.snackcolor = 'green';
          this.message = 'Image Deleted Successfully';
          this.fetchLogs();
        })
        .catch((error) => {
          console.error(error.response.data.error.message);
          this.snackbar = true;
          this.snackcolor = 'red';
          this.message = error.response.data.error.message;
        });
    },
  },
  async created() {
    if (sessionStorage.length == 0) {
      this.$router.push({
        name: 'Login',
      });
    }
    this.tenant = {
      id: sessionStorage.tenant_id,
      name: sessionStorage.tenant_name,
    };
    await this.fetchLogs();
  },
  watch: {
    $route(to, from) {
      this.fetchLogs(to.params.type);
    },
  },
};
</script>

<style scoped lang="scss">
.theme--light.v-btn {
  color: white;
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
</style>
