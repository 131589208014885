<style src="../assets/css/handsontable.full.css"></style>
<style scoped>
  /deep/ .header, /deep/ .handsontable th {
  color: #3c3c3c !important;
  font-weight: 600 !important;
  background-color: white !important;
  }

  /deep/ .handsontable td, .handsontable th {
  padding: 3px;
  }

  /deep/ .handsontable * {
  text-align: left !important;
  }

  /deep/ .ht_clone_top {
  z-index: 1;
  }

  /deep/ .heading {
  border: None !important;
  background-color: #3a9bdc !important;
  color: white !important;
  font-weight: 650 !important;
  font-size: 0.9rem;
  }

  /deep/ .change{
    background-color: aqua;
  }

  /deep/ .add{
    background-color: #B0DC38;
  }

  /deep/ .delete{
    background-color: #fb3b1e;
  }
</style>
<template>
    <div>
      <hot-table ref="hotTable" :settings="hotSettings" :data="handsOnTableData" :key="refreshHotTable"></hot-table>
    </div>
</template>
  
<script>
import { HotTable } from '@handsontable/vue';
import Handsontable from 'handsontable';
  export default {
    name: 'RequirementGatheringSheet',
    components:{
        HotTable
    },
    props: {
      template: Object,
      data : Array,
      readOnly: Boolean,
    },
    data() {
      return {
        hotSettings:{
            autoRowSize: true,
            manualColumnResize: true,
            height:"calc(100vh - 140px)",
            licenseKey: "80a62-50798-fdae5-c472b-0a403"
        },
        handsOnTableData: {},
        refreshHotTable: 0,
        defaultValues: {"numeric":0, "text":"", "checkbox":false},

        undoStack: [],
      }
    },
    methods: {
      transform(data, template){
        template.fields.forEach((field) => {
          field["values"] = data.map((obj) => {
            if(field.id){
              return obj[field.id]
            }
          })
        })
        return template.fields;
      },
      getColumns(){
        const columns = [{data:"label", readOnly: true, width: 400, className: 'header'}]
        const cond = this.template.fields.some((row) => {
          return row.available_options ? true : false;
        })
        if(cond){
          columns.push({data:'available_options', readOnly:true, width:400, className:"header"});
        }

        for(let i = 0 ; i<this.data.length; i++){
          columns.push({data: `values.${i}.value`, width: 400});
        }
        return columns
      },
      getContextMenuConfig(){
        const mapper = {
          add_entry_above: this.createContextMenu_AddEntryAbove(),
          add_entry_below: this.createContextMenu_AddEntryBelow(),
          delete_entry: this.createContextMenu_DeleteEntry(),
          add_loan_purpose: this.createContextMenu_AddLoanPurpose(),
          undo: this.createContextMenu_Undo()
        }
        return {items:mapper}
      },
      createContextMenu_AddEntryBelow(){
        return {
          disabled: () => this.template.style === "keyvalue",
          name: () => {
            return "<span  class='small'>Add Entry Below</span>"
          },
          callback: (key, selection, event) => {
            let index = -1;
            if(this.template.style === "horizontal"){
              index = selection[0].start.row
            }else{
              index = this.$refs['hotTable'].hotInstance.colToProp(selection[0].start.col).split(".")[1]
            }

            this.undoStack.push({
              operation: "Addition",
              index: index+1
            })

            const newObj = this.createNewObject()
            this.data.splice(index+1, 0, newObj)
            if(this.template.style === "vertical"){
              this.handsOnTableData = this.transform(this.data, this.template);
              this.hotSettings["columns"] = this.getColumns();
              this.refreshHotTable+=1;
            }
          }
        }
      },
      createContextMenu_AddEntryAbove(){
        return {
          disabled: () => this.template.style === "keyvalue",
          name: () => {
            return "<span  class='small'>Add Entry Above</span>"
          },
          callback: (key, selection, event) => {
            let index = -1;
            if(this.template.style === "horizontal"){
              index = selection[0].start.row
            }else{
              index = this.$refs['hotTable'].hotInstance.colToProp(selection[0].start.col).split(".")[1]
            }

            this.undoStack.push({
              operation: "Addition",
              index: index
            })

            const newObj = this.createNewObject()
            this.data.splice(index, 0, newObj);
            if(this.template.style === "vertical"){
              this.handsOnTableData = this.transform(this.data, this.template);
              this.hotSettings["columns"] = this.getColumns();
              this.refreshHotTable+=1;
            }
          }
        }
      },
      createContextMenu_DeleteEntry(){
        return {
          disabled: () => {
            const table = this.$refs['hotTable'].hotInstance
            const colIndex = table.getSelectedLast()[1];
            const prop = table.colToProp(colIndex);
            return this.template.style === "keyvalue" || prop === "label" || prop === "available_options";
          },
          name: () => {
            return "<span class='small'>Delete Entry</span>"
          },
          callback: (key, selection, event) => {
            let index = -1;
            if(this.template.style === "horizontal"){
              index = selection[0].start.row
            }else{
              index = this.$refs['hotTable'].hotInstance.colToProp(selection[0].start.col).split(".")[1]
            }

            this.undoStack.push({
              operation: "Deletion",
              index: index,
              data: this.data[index]
            })

            this.data.splice(index, 1)
            if(this.template.style === "vertical"){
              this.handsOnTableData = this.transform(this.data, this.template);
              this.hotSettings["columns"] = this.getColumns();
              this.refreshHotTable+=1;
            }
          }
        }
      },
      createContextMenu_AddLoanPurpose(){
        return {
          hidden: () => this.template.id != "product_summary",
          name: () => {
            return "<span class='small'>Add loan purpose</span>"
          },
          callback: (key, selection, event) => {
            let index = selection[0].start.row;

            this.undoStack.push({
              operation: "Addition",
              index: index+1
            })

            const obj = this.data[index];
            const newObj = this.createNewObject()
            newObj["product_name"] = obj["product_name"]
            newObj["min_loan_amount"] = obj["min_loan_amount"]
            newObj["max_loan_amount"] = obj["max_loan_amount"]

            this.data.splice(index+1, 0, newObj);
          }
        }
      },
      createContextMenu_Undo(){
        return {
          disabled: () => this.undoStack.length==0,
          name: () => {
            return "<span class='small'>Undo</span>"
          },
          callback: (key, selection, event) => {
            const op = this.undoStack.pop();
            if(op.operation == "Addition"){
              this.data.splice(op.index, 1);
            }else{
              this.data.splice(op.index, 0, op.data)
            }

            if(this.template.style === "vertical"){
              this.handsOnTableData = this.transform(this.data, this.template);
              this.hotSettings["columns"] = this.getColumns();
              this.refreshHotTable+=1;
            }
          }
        }
      },
      createNewObject(){
        const obj = {};
        this.template.fields.forEach((field) => {
          obj[field.id] = {"value": this.defaultValues[field.type]}
        })
        return obj;
      }

    },
    watch : {
      readOnly(newValue, oldValue){
        this.hotSettings["readOnly"] = this.readOnly;
        this.refreshHotTable +=1;
      }
    },
    created(){
      this.hotSettings["readOnly"] = this.readOnly;
      this.hotSettings["contextMenu"] = this.readOnly ? false : this.getContextMenuConfig();
      
      if(this.template.style === "vertical" || this.template.style == "keyvalue"){
        this.handsOnTableData = this.transform(this.data, this.template)
        this.hotSettings["columns"] = this.getColumns();
        this.hotSettings["cells"] = (row, col, prop) => {
          var cellProperties = {};
          const column = this.template.fields[row];

          if(column.style == "heading"){
            cellProperties.className = "heading";
            cellProperties.readOnly = true;
          }else if(prop != "label" && prop!="available_options"){
            cellProperties = column;
            cellProperties.type = Handsontable.cellTypes[column.type]

            const indx = prop.split(".")[1];
            const field = this.template.fields[row].id;
            if(this.data[indx][field].updated){
              cellProperties.className = this.data[indx][field].className || "change";
            }else{
              cellProperties.className = "";
            }
          }

          return cellProperties;
        }
      }else{
        this.handsOnTableData = this.data;
        this.hotSettings["columns"] = this.template.fields.map((column) => {
          column.data = `${column.id}.value`;
          column.width = column.width ? column.width : 200;
          column.type = Handsontable.cellTypes[column.type]
          return column;
        })
        this.hotSettings["colHeaders"] = this.template.fields.map((column) => {
          return column.label;
        })
        this.hotSettings["rowHeaders"] = true;
        this.hotSettings["cells"] = (row, col, prop) => {
          var cellProperties = {};
          const column = this.template.fields[col];
          

          if(column.dependency){
            if(this.data[row] && this.data[row][column.dependency.id].value != column.dependency.value){
              cellProperties.readOnly = true;
            }else{
              cellProperties.readOnly = false;
            }
          }

          const indx = row;
          const field = prop.split(".")[0];
          if(this.data[indx][field].updated){
            cellProperties.className = this.data[indx][field].className || "change";
          }else{
            cellProperties.className = ""
          }
          return cellProperties;
        }
      }
    }


  };
  </script>
  